import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import { CookiesProvider } from "react-cookie";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <CookiesProvider>
      <App />
    </CookiesProvider>
);

// export const SERVER_URL = "http://52.3.32.61:5000"
// export const SERVER_URL = "http://localhost:5000" 
export const SERVER_URL = "https://mbiapis.setucodeverse.net"
// export const SERVER_URL = "https://mbiapis.setucodeverse.net"

